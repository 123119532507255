/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.main {
  @apply flex-1;
  @apply flex flex-col gap-y-3;
}

.two_fields {
  @apply grid grid-cols-2 gap-x-3;
}

.actions {
  @apply mt-auto;
  @apply pt-2;
  @apply flex flex-col;
  @apply w-full;
}
