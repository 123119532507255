/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.header {
  @apply mb-3;
  @apply flex items-center justify-end;
}

.header > button {
  @apply flex items-center justify-center gap-x-2;
}
