/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.title {
  @apply font-semibold mb-2;
}

.operation {
  @apply p-4 py-4 mt-2 mb-4 rounded-2xl;
  @apply border border-base-80;
  @apply flex justify-between items-center;
}

.fiat_value {
  @apply text-text-50;
}

.section {
  @apply p-2 py-4 mt-2 mb-4 rounded-2xl bg-base-90;
  @apply text-sm;
}

.list li {
  @apply flex justify-between items-center;
}

.list li + li {
  @apply mt-4;
}

.list .description {
  @apply text-xs text-text-60;
  @apply pr-1;
}

.list .value {
  @apply break-all text-right font-semibold;
}
