/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.ant-select,
.ant-select-single .ant-select-selector {
  @apply font-primary;
  @apply bg-base-90;
  @apply text-text-100;
  @apply !rounded-xl;
}

.ant-select .ant-select-selector {
  @apply !text-sm;
  @apply !rounded-xl;
  @apply !px-3;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-search {
  @apply m-0;
}

.ant-select .ant-select-selection-placeholder {
  @apply !text-text-40;
}

.ant-select .ant-select-selection-item {
  @apply !font-normal;
}

.ant-select.ant-select-lg {
  @apply h-[2.625rem];
}

.ant-select-dropdown,
.ant-select-dropdown .ant-select-item {
  @apply rounded-xl;
}

.ant-select .ant-select-selection-item {
  @apply font-semibold;
}

.ant-select .ant-select-arrow {
  @apply text-inherit;
  @apply right-4;
}

.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  @apply border-base-80;
}

.ant-select-focused:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector,
.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ):hover
  .ant-select-selector {
  @apply !border-primary-100;
}

.ant-input-lg {
  @apply py-2;
}

.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  @apply bg-primary-100/15;
}

.ant-select-filled:not(.ant-select-customize-input) .ant-select-selector {
  @apply bg-base-80 text-text-100;
}

/*--- Start Select Option Icon ---*/
.ant-select-item-option-content,
.ant-select-selection-item {
  @apply flex items-center;
}

.ant-select .ant-select-item-icon,
.ant-select-item-option-content .ant-select-item-icon {
  @apply w-6 h-6 mr-2;
}

.ant-select .ant-select-item-icon svg,
.ant-select-item-option-content .ant-select-item-icon svg {
  @apply w-full h-full;
}

.ant-select .ant-select-item-icon img,
.ant-select-item-option-content .ant-select-item-icon img {
  @apply w-full h-full;
  @apply object-contain;
}

.ant-select-sm .ant-select-item-icon {
  @apply w-4 h-4 mr-2;
}

/*--- End Select Option Icon ---*/

/*--- Start Dark Mode Select ---*/
.dark-mode .ant-select {
  @apply text-text-05;
}

.dark-mode .ant-select-selection-placeholder {
  @apply text-text-40;
}

.dark-mode
  .ant-select-filled:not(.ant-select-customize-input)
  .ant-select-selector {
  @apply bg-base-05 text-text-05;
}

.dark-mode
  .ant-select-filled:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  @apply bg-base-10;
}

.dark-mode
  .ant-select-focused.ant-select-filled:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  @apply bg-base-20;
}

.dark-mode
  .ant-select-single.ant-select-lg.ant-select-open
  .ant-select-selection-item {
  @apply text-text-20;
}
/*--- End Dark Mode Select ---*/
