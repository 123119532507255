/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.main {
  @apply w-full;
}

.container {
  @apply rounded-xl overflow-hidden;
  @apply flex items-center gap-2 px-3;
  @apply border border-base-80;
  @apply transition-colors duration-200;
}

.container:focus-within,
.container:hover {
  @apply border-primary-100;
}

.container svg {
  @apply text-lg;
}

.input {
  @apply w-full outline-none;
  @apply text-base;
  @apply min-h-10;
}

.input::placeholder {
  @apply text-sm;
  @apply opacity-100;
  @apply font-normal;
}

.error {
  @apply text-system-error;
  @apply px-1;
  @apply text-sm;
}

.primary .container,
.primary .input {
  @apply bg-base-100 text-text-100;
}

.primary .input::placeholder {
  @apply text-text-40;
}

.secondary .container,
.secondary .input {
  @apply bg-base-90 text-text-100;
}

.secondary .input::placeholder {
  @apply text-text-40/80;
}
